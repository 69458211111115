export default class GtmHelper {
    constructor() {
        if (!window.dataLayer) {
            window.dataLayer = [];

    }

        if (document.querySelectorAll('form').length) {

            this.uforms();

        }

        if (document.querySelector('.accordion')) {
            this.accordion();
        }

        // checking if a membership has been successful
        if (top.window.document.referrer.toLowerCase().includes('dms.ftfa.dk')) {
            window.dataLayer.push({ 'event': 'new_member' });
        }
    }


    accordion() {
        document.querySelectorAll('.accordion-button').forEach(item => {
            item.addEventListener('click', e => {
                if (e.target.dataset.gtmEvent === 'faq-section-opened') {
                    e.target.dataset.gtmEvent = 'faq-section-closed';
                } else {
                    e.target.dataset.gtmEvent = 'faq-section-opened';
                }
            });
        });
    }

    // umbraco forms tracking
    uforms() {
        document.querySelectorAll('form').forEach(uform => {

            uform.onsubmit = uformGtmTracking;
           
        });

        function uformGtmTracking(e)
        {
            e.preventDefault();
            var formName = e.target.FormName.value;
            var formId = e.target.FormId.value
            var formIdNormalized = document.querySelector("#umbraco_form_" + formId.replace(/-/g, ''));
            // check if there are validation errors
            if (formIdNormalized.getElementsByClassName('dataconsent')[0].getElementsByTagName('input')[0].checked && !formIdNormalized.getElementsByClassName('field-validation-error').length) {               
                
                var templateName = formIdNormalized.getElementsByClassName("templatename")[0].getElementsByClassName("hidden")[0].value;
                // var name = formIdNormalized.getElementsByClassName("name")[0].getElementsByClassName("text")[0].value;

                // pushing which form is submitted
                window.dataLayer.push({
                    'event': templateName,
                    'formVariant': formName
                });
                
            // rest of tracking
            }
            


        }


    }
}
