export default class ComponentRepository {
    constructor(componentSourceMap) {
        // The property name is the key used to instantiate the component.
        // The property value is the path of the file (navigated from the scripts folder) excluded the file ending.
        // The order they appear in the sourcemap will affect the order they are instantiated in.
        // Shared components should generally be instantiated before controllers.
        this.componentSourceMap = componentSourceMap;

        this.componentClassPromiseMap = {}; // Will hold promises for all for classes needed to instantiate components
        this.globalInstances = {}; // Will hold components with a data-component-id, that can be queried globally
        this.loadedComponents = {}; // Will hold all loaded component instances

        // initializedPromise will resolve when the load function has run and all components have been initialized.
        this.initializedPromiseResolve = null;
        this.initializedPromiseReject = null;
        this.initializedPromise = new Promise((resolve, reject) => {
            this.initializedPromiseResolve = resolve;
            this.initializedPromiseReject = reject;
        });

        // Load all classes required for the components on the current page.
        for (const componentKey in this.componentSourceMap) {
            if (document.querySelector('[data-component="' + componentKey + '"]')) {
                this.loadComponentClass(componentKey);
            }
        }

        this.load();
    }

    /*
     * Iterates all elements in the DOM with the 'data-component' attribute,
     * and instantiates the corresponding component.
     * Returns a promise that will resolve when all components have been instantiated or have failed.
     */
    load() {
        const loadComponentsPromiseArray = [];

        document.querySelectorAll('[data-component]').forEach(c => {
            const componentKey = c.getAttribute('data-component');
            const componentId = c.getAttribute('data-component-id');
            const componentArgs = c.getAttribute('data-component-args');



            if (this.componentClassPromiseMap[componentKey]) {
                const loadPromise = new Promise((resolve, reject) => {
                    this.componentClassPromiseMap[componentKey]
                        .then(Component => {
                            let args = null;
                            if (componentArgs) {
                                args = JSON.parse(componentArgs);
                            }

                            const newComponentInstance = new Component(c, args);

                            if (!this.loadedComponents[componentKey]) {
                                this.loadedComponents[componentKey] = [];
                            }
                            this.loadedComponents[componentKey].push(newComponentInstance);

                            // If the component has a component ID, add it to globalInstances
                            if (componentId) {
                                this.globalInstances[componentId] = newComponentInstance;
                            }

                            resolve(newComponentInstance);
                        })
                        .catch(error => {
                            console.error('Error instantiating component: ', componentKey, error);
                            reject();
                        });
                });
                loadComponentsPromiseArray.push(loadPromise);
            } else {
                console.warn('unable to find component: ', componentKey);
                console.warn(c);
            }
        });

        return Promise.all(loadComponentsPromiseArray)
            .then(() => {
                this.initializedPromiseResolve();
            });
    }

    /**
     * Returns a promise that will eventually resolve with
     * the class corresponsing the component key.
     */
    loadComponentClass(key) {
        if (this.componentClassPromiseMap[key]) {
            return this.componentClassPromiseMap[key];
        }

        this.componentClassPromiseMap[key] = this.componentSourceMap[key]()
            .then(componentLoad => {
                return componentLoad.default;
            });
        return this.componentClassPromiseMap[key];
    }


    /**
     * Returns a promise that will eventually resolve with
     * a specific instance of a component, based on its ID.
     */
    getGlobalInstance(instanceId) {
        return this.initializedPromise
            .then(() => {
                return instanceId ? this.globalInstances[instanceId] : undefined;
            });
    }


    /**
     * Returns a promise that will eventually resolve with
     * all instances of a specific type.
     */
    getInstancesOfType(key) {
        return this.initializedPromise
            .then(() => {
                return this.loadedComponents[key];
            });
    }
}
