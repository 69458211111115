import ComponentRepository from './architecture/componentRepository';
import GtmHelper from './helpers/gtm';
import ScrollHelper from './helpers/ScrollHelper';

document.addEventListener('DOMContentLoaded', function () {
    window.cr = new ComponentRepository({
        header: () => import('./components/top-navigation'),
        'mobile-header': () => import('./components/mobile-header'),
        accordions: () => import('bootstrap/js/src/collapse'),
        'hero-swiper': () => import('./components/hero-swiper'),
        'short-content-swiper': () =>
            import('./components/short-content-swiper'),
        video: () => import('./components/video'),
        'image-gallery': () => import('./components/image-gallery'),
        search: () => import('./components/search'),
        'card-slider': () => import('./components/card-slider'),
        'news-overview': () => import('./components/news-overview'),
        'events-overview': () => import('./components/events-overview'),
        share: () => import('./components/share'),
        'signup-event': () => import('./components/signup-event'),
        'annual-index': () => import('./components/annual-index'),
        chat: () => import('./components/chat'),
        calculator: () => import('./components/calculator'),
        'jobmakker-barba': () =>
            import('./components/jobmakker/jobmakker-barba'),
        'jobmakker-jobapplication': () =>
            import('./components/jobmakker/jobmakker-jobapplication'),
        'jobmakker-auth-form': () =>
            import('./components/jobmakker/jobmakker-auth-form'),
        'jobmakker-instant-feedback-jobapplication': () =>
            import(
                './components/jobmakker/jobmakker-instant-feedback-jobapplication'
            ),
        'jobmakker-competence-profile': () =>
            import(
                './components/jobmakker/jobmakker-competence-profile'
            ),
        'jobmakker-instant-feedback-cv': () =>
            import(
                './components/jobmakker/jobmakker-instant-feedback-cv'
            ),
    });

    new ScrollHelper();
    new GtmHelper();
});
