export default class ScrollHelper {
    constructor() {
        this.elm = undefined;
        this.nug = 300;

        if (window.location.hash !== '') {
            this.init();
        }
        
        this.event();
    }

    init() {
        this.elm = document.querySelector(window.location.hash);

        if (!this.elm) {
            return;
        }

        window.scrollTo(0, 0);
        this.elm.focus();

        if (this.elm.classList.contains('accordion-item')) {
            this.elm.querySelector('.accordion-button').classList.remove('collapsed');
            this.elm.querySelector('.accordion-button').ariaExpanded = true;
            this.elm.querySelector('.accordion-button').dataset.gtmEvent = 'faq-section-closed';
            this.elm.querySelector('.accordion-collapse').classList.add('show');
        }

        window.scrollTo(0, this.elm.offsetTop - this.nug);

        window.addEventListener('hashchange', () => {
            this.elm = document.querySelector(window.location.hash);

            if (!this.elm) {
                return;
            }

            window.scrollTo(0, 0);
            this.elm.focus();

            if (this.elm.classList.contains('accordion-item')) {
                document.querySelectorAll('.accordion-item').forEach(item => {
                    item.querySelector('.accordion-button').classList.add('collapsed');
                    item.querySelector('.accordion-button').ariaExpanded = false;
                    item.querySelector('.accordion-button').dataset.gtmEvent = 'faq-section-open';
                    item.querySelector('.accordion-collapse').classList.remove('show');
                });

                this.elm.querySelector('.accordion-button').classList.remove('collapsed');
                this.elm.querySelector('.accordion-button').ariaExpanded = true;
                this.elm.querySelector('.accordion-button').dataset.gtmEvent = 'faq-section-closed';
                this.elm.querySelector('.accordion-collapse').classList.add('show');

                window.cr.loadedComponents['header'][0].search.close();
                window.cr.loadedComponents['mobile-header'][0].search.close();
            }

            window.scrollTo(0, this.elm.offsetTop - this.nug);
        });
    }

    event() {
        document.querySelectorAll('.accordion-button').forEach(item => {
            item.addEventListener('click', e => {
                const old = window.scrollY;

                window.location.hash = e.currentTarget.parentNode.parentNode.id;

                window.scrollTo(0, old);

                this.elm = document.querySelector(window.location.hash);

                setTimeout(() => window.scrollTo(0, this.elm.offsetTop - this.nug), 300);
            });
        });

        document.addEventListener('selectionchange', () => {
            const select = document.getSelection();
            const getId = (elm) => {
                if (!elm) return undefined;
                if (elm.nodeName && elm.nodeName === 'SECTION') return undefined;

                if (elm.dataset && elm.dataset.itemAnchor && elm.dataset.itemAnchor !== '') {
                    return elm.dataset.itemAnchor;
                }

                return getId(elm.parentNode);
            };

            if (getId(select.baseNode) === 'selction-helper') {
                return;
            } else if (document.querySelector('#selction-helper')) {
                setTimeout(function () {
                    if (document.querySelector('#selction-helper')) document.querySelector('#selction-helper').remove() }, 200);
            }

            if (select.baseNode === undefined || select.type !== 'Range') return;

            const id = getId(select.baseNode);
            if (id === undefined) return;

            const parent = select.extentNode.parentNode;
            const pos = select.getRangeAt(0).getBoundingClientRect();
            const child = document.createElement('DIV');
            child.style.cssText = 'position: absolute;top:'+(parent.offsetTop-58)+'px;left:'+(pos.left + (pos.width / 2)) +'px;background: black;color: #FFF;padding: 10px';
            child.id = 'selction-helper';
            child.innerHTML = '<span>Kopier link</span>';
            child.addEventListener('click', e => {
                e.preventDefault();
                window.location.hash = id;
                this.elm = document.querySelector(window.location.hash);
                setTimeout(() => window.scrollTo(0, this.elm.offsetTop - this.nug), 300);

                const el = document.createElement('textarea');
                el.value = window.location.href;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                document.querySelector('#selction-helper').remove();
            });

            document.body.appendChild(child);
        });
    }
}
